'use client';

import { NavMenuItem } from '@/lib/parsers/nav';
import Btn, { BtnProps } from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';

type FooterItemProps = {
  item: NavMenuItem;
  BtnProps?: BtnProps;
} & FlexProps;

export default function FooterItem({ item, BtnProps, ...props }: FooterItemProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, subItems, newWindow, external, parentId, ...rest } = item;
  const hasSubItems = !!subItems?.length;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { href, ...passiveBtnProps } = rest;

  return (
    <Flex spacing="sm" {...props}>
      <Flex direction="row">
        {type === 'link' ? (
          <Btn variant="nav" size="small" color="blue" underline="hover" {...rest} {...BtnProps} />
        ) : (
          <Btn
            variant="nav"
            size="small"
            color="blue"
            underline="hover"
            {...passiveBtnProps}
            {...BtnProps}
          />
        )}
      </Flex>
      {hasSubItems && (
        <Flex as="ul" spacing="sm" cx={{ listStyle: 'none' }}>
          {subItems.map((subItem) => (
            <FooterItem
              key={subItem.id}
              cx={{ zIndex: 'navbar' }}
              item={subItem}
              BtnProps={{ color: 'grey' }}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
}

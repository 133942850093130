'use client';

import Box from '@/components/ui/Box';
import Btn from '@/components/ui/Btn';
import Container from '@/components/ui/Container';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { Drawer } from '@mui/material';
import { useTranslations } from 'next-intl';
import { createPersistStore } from '../../lib/utils/createPersistStore';
import Flex from '../ui/Flex';

type CookieConsentProps = {
  heading?: string | null;
  content?: string | null;
};

const useCookieStore = createPersistStore('cookie-consent');

const CookieConsent = (props: CookieConsentProps) => {
  const t = useTranslations('cookie');
  const { value, setValue } = useCookieStore();

  const isSet = value !== null;

  const heading = props.heading ?? t('heading');
  const content = props.content ?? t('description');

  if (isSet) return null;

  return (
    <Drawer anchor="bottom" open={true} PaperProps={{ elevation: 24 }} variant="persistent">
      <Box as="section" colorSet="pinkLight" paper cx={{ pY: 'md' }}>
        <Container>
          <Grid rowGutter cx={{ alignItems: 'center' }}>
            <Grid.Col md={8}>
              <Txt as="p" variant="lg" bold noMargin>
                {heading}
              </Txt>
              <Txt html variant="md" color="black">
                {content}
              </Txt>
            </Grid.Col>
            <Grid.Col md={4}>
              <Flex direction="row" spacing="md" cx={{ justifyContent: { md: 'end' } }}>
                <Btn color="blue" scale="small" onClick={() => setValue(true)}>
                  {t('dismiss')}
                </Btn>
              </Flex>
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
    </Drawer>
  );
};

export default CookieConsent;
